import { Injectable, inject, signal } from '@angular/core';
import { BadRequestDto } from '@data-access/bulk-operations-api';
import {
  WebSocketClient,
  WsActionV3Completed,
  WsServerPayloadEnum,
} from '@data-access/bulk-operations-ws';
import { IcEmployeesService } from '@item-cache';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

import { ToasterService } from '#shared/components/toaster/toaster.service';

import { PanelState } from '../common/panel-state.model';

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class EmployeeActionsToastService {
  private readonly webSocketClient = inject(WebSocketClient);
  private readonly toasterService = inject(ToasterService);
  private readonly icEmployeesMap = inject(IcEmployeesService).valuesMap;
  public readonly panelState = signal<PanelState>('viewing');

  constructor() {
    this.subscribeToWebSocket();
  }

  private subscribeToWebSocket() {
    this.webSocketClient
      .getMessagesByType(WsServerPayloadEnum.EmployeeCardEditActionCompleted)
      .pipe(
        untilDestroyed(this),
        tap((data) => this.handleWebSocketEvent(data)),
      )
      .subscribe();
  }

  private handleWebSocketEvent(data: WsActionV3Completed) {
    if (!data.errorMessage) {
      this.showSuccessToast(data);
      this.panelState.set('viewing');
    } else {
      this.showErrorToast(data.employeeId, data.errorMessage);
      this.panelState.set('editing');
    }
  }

  private showSuccessToast(data: WsActionV3Completed) {
    const employee = this.icEmployeesMap().get(data.employeeId);

    const employeeNumber = employee?.customId;
    const firstName = employee?.firstName;
    const lastName = employee?.lastName;
    const toastMessage = $localize`data were saved!`;

    this.toasterService.showSuccess(
      `${employeeNumber} - ${firstName} ${lastName} ${toastMessage}`,
      data.actionV3Id,
    );
  }

  private showErrorToast(employeeId: string, errorMessage: string) {
    const errorMsg = $localize`Employee data were not saved!`;
    const errorId = `${employeeId}:${errorMessage}`;
    this.toasterService.showError(`${errorMsg} ${errorMessage}`, errorId);
  }

  public handleSaveError(error: BadRequestDto): void {
    const errorMsg = $localize`Error while saving changes!`;
    const errorDetails = error?.validationItems
      ? error.validationItems.map((item) => `${item.propertyName}: "${item.message}"`).join('; ')
      : error?.generalError || 'Unknown error';

    this.toasterService.showError(
      `${errorMsg} ${errorDetails}`,
      error?.generalError || 'saveError',
    );
  }

  public handleCreateSuccess(personalDetails: PersonalDetails) {
    this.toasterService.showSuccess(
      $localize`New position for employee ${personalDetails.employeeNumber} - ${personalDetails.firstName} ${personalDetails.lastName} was created successfully`,
    );
  }

  public handleHiringSuccess(personalDetails: PersonalDetails) {
    this.toasterService.showSuccess(
      $localize`${personalDetails.employeeNumber} - ${personalDetails.firstName} ${personalDetails.lastName} was hired successfully`,
    );
  }

  public handleHiringError(error) {
    this.toasterService.showError(
      $localize`Unhandled error when creating employee. ${error}`,
      'unhandled-error-creating-employee',
    );
  }
}

type PersonalDetails = {
  employeeNumber: string;
  firstName: string;
  lastName: string;
};
