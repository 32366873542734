import { NgTemplateOutlet } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { FormFieldErrorsService } from './form-errors.service';

let nextUniqueId = 0;

@Component({
  selector: 'di-errors',
  imports: [NgTemplateOutlet],
  templateUrl: './form-errors.component.html',
})
export class FormFieldErrorsComponent {
  protected readonly formErrorsService = inject(FormFieldErrorsService);

  public readonly id = `di-error-message-${++nextUniqueId}` as const;

  public readonly errors = input.required<ValidationErrors | null>();
  protected readonly errorKeys = computed(() => Object.keys(this.errors() ?? {}));

  errorTrackFn(errKey: string) {
    // TODO: temporal workaround to bypass false-positive NG0956 warning: https://github.com/angular/angular/issues/56471
    return errKey;
  }
}
